import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { WorkESIOHosts } from "./constants/Common/Constants";

import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import { MSAL_CONFIG, RAFAuthModule, loginRequest } from "./RAFAzure/AuthModule";
import "./index.css";
import reportWebVitals from "./reportWebVitals";



export let msalInstance = new RAFAuthModule(MSAL_CONFIG);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  //msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    //msalInstance.setActiveAccount(account);
  }
  else if (
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    event.eventType === EventType.SSO_SILENT_FAILURE ||
    event.eventType === EventType.LOGIN_FAILURE
  ) {
    console.error('Authentication error:', event.error);
    msalInstance.loginRedirect(loginRequest);
  }
});

function initializeSentry() {
  if (window.location.hostname !== WorkESIOHosts.localhost) {
    console.log("Initializing Sentry...");
    Sentry.init({
      dsn: "https://ac7e983b79b8a91dc16d62ed6e22a730@o4507066187055104.ingest.us.sentry.io/4507066319306752",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        // Sentry.feedbackIntegration({
        //   // Additional SDK configuration goes in here, for example:
        //   colorScheme: "system",
        //   // isNameRequired: true,
        //   // isEmailRequired: true,
        // }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // beforeSend(event, hint) {
      //   // Check if it is an exception, and if so, show the report dialog
      //   if (event.exception && event.event_id) {
      //     Sentry.showReportDialog({ eventId: event.event_id });
      //   }
      //   return event;
      // },
    });
  }
}

const root = ReactDOMClient.createRoot(document.getElementById("root"));


//const app = initializeApp(firebaseConfig);
//export const firestoreDatabase = getFirestore(app);
// export const firestoreDatabase = initializeFirestore(app, {
//   ignoreUndefinedProperties: true,
// });

function MainApp() {
  const [isSentryInitialized, setSentryInitialized] = useState(false);

  useEffect(() => {
    // Initialize Sentry
    initializeSentry();

    Promise.all([
      import("@syncfusion/ej2-base"),
      import("survey-core")
    ]).then(([ejBase, surveyBase]) => {
      ejBase.registerLicense(
        //'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0djWn1Wc31UR2dc'//26
        'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZjWXxcdHBWT2hZ'//27
      );
      surveyBase.setLicenseKey(
        "N2E5MDA1OWYtOTVmZS00Mjc2LThmMDUtOWFkM2M0OWJmNmU5OzE9MjAyNS0wNS0wOQ=="
      );
      setSentryInitialized(true);
    }).catch(error => {
      console.error("Error loading modules:", error);
    });

  }, []);

  useEffect(() => {
    if (isSentryInitialized) {

      import("./RAFMaster/RMModules/FormLibrary/components/SurveyCreator/Initializations").then(ejBase => {
        ejBase.initialiseRAFSurveySettings();
      });

    }
  }, [isSentryInitialized]);

  if (!isSentryInitialized) {
    return <p>Loading...</p>;
  }
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <BrowserRouter>
          <App pca={msalInstance} />
        </BrowserRouter>
      </RecoilRoot>
    </ErrorBoundary>
  );

}

root.render(
  <MainApp />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
